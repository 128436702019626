import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Games({ source, src, alt }) {
  return (
    <>
      <Link to={source}>
        <GameImg src={src} alt={alt} />
      </Link>
    </>
  );
}

const GameImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: 5px;
`;
