import styled, { keyframes } from 'styled-components';
import theme from '../../../styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.primary};;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Main = styled.div`
 display: flex;
  margin-top: 22px;
  max-width: 700px;
  align-items: center;
  justify-content: center;  
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-top: 120%;
    width: 100%;
  }
`;
export const Fruit = styled.div`
  font-size: 24px;
  margin-top: 10px;
  align-items: center;
  margin: 2px;
  animation: fruitFloat 4s ease-in-out infinite; /* Adicione a animação de flutuação */

  img {
    width: 50px;
    height: auto;
  }

  @keyframes fruitFloat {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
}`;

export const FruitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  margin-bottom: -53px;
  flex-wrap: flex; /* Altere para "wrap" */
`;

export const Loading = styled.img`
  width: 20px;
`;

export const LogoImage = styled.img`
  width: 100px;
  animation: flutuar 2s ease-in-out infinite;

  @keyframes flutuar {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media (max-width: 768px) {
    padding-top: 8%;
  }
`;

export const MinesField = styled.div`
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  width: 90%;
  height: 200px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
`;

export const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 5px;
`;

export const IdentifySignal = styled.button`
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: ${theme.colors.button};

  transition: 100ms;

  &:hover {
    opacity: 0.5;
  }
`;

export const WaitButton = styled.div`
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: ${theme.colors.button};
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Counter = styled.p`
  display: flex;
  margin-top: 12px;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-bottom: 5px;
`;

export const ResultCounter = styled.p`
  /* Add your styles for the ResultCounter here */
  /* For example:
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  */
`;
