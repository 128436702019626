import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

import Menu from "../../components/Menu";
import Navbar from "../../components/Navbar";
import thumb1 from "../../assets/Thumb_1_ROXA.png";
import thumb2 from "../../assets/Thumb_2_RED.png";

const purpleColor = "#8A3FFC";

const thumbnails = [
  {
    title: "COMO JOGAR ?",
    imageUrl: thumb1,
    link: "/home",
    buttonText: "JOGAR AGORA",
    videoUrl: "https://www.youtube.com/embed/BNaYU8rw184", // Modificado para URL de incorporação do YouTube
  },
  {
    title: "COMO SE CADASTRAR ?",
    imageUrl: thumb2,
    link: "/Pos_Login",
    buttonText: "CADASTRAR-SE",
    videoUrl: "https://www.youtube.com/embed/2EPyz30I4ro", // Modificado para URL de incorporação do YouTube
  },
];

function VideoModal({ isOpen, onClose, videoUrl }) {
  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Vídeo Tutorial"
    >
      <ModalContent>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <iframe
          width="300" // Aumente o valor conforme necessário
          height="300"
          src={videoUrl}
          title="Vídeo Tutorial"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </ModalContent>
    </CustomModal>
  );
}

export default function Tutorials() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const openModal = (url) => {
    setModalIsOpen(true);
    setVideoUrl(url);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setVideoUrl("");
  };

  return (
    <Container>
      <Navbar title="Tutoriais" />
      <MenuWithMargin />
      <ScrollableMain>
        {thumbnails.map((thumbnail, index) => (
          <Tutorial key={index} onClick={() => openModal(thumbnail.videoUrl)}>
            <TutorialTitle>{thumbnail.title}</TutorialTitle>
            <ThumbnailImage src={thumbnail.imageUrl} alt={thumbnail.title} />
            <ReadMoreLink to={thumbnail.link} style={{ backgroundColor: purpleColor }}>
              {thumbnail.buttonText}
            </ReadMoreLink>
          </Tutorial>
        ))}
        {/* Espaço extra para evitar corte no celular */}
        <MobileSpace />
      </ScrollableMain>
      <Menu active="tops" />
      <VideoModal isOpen={modalIsOpen} onClose={closeModal} videoUrl={videoUrl} />
    </Container>
  );
}

const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1B2026;
  @media (max-width: 768px) {
    height: 105%;
  }
`;

const MenuWithMargin = styled(Menu)`
  margin-bottom: 50px;
`;

const ScrollableMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding-bottom: 50px; /* Espaço extra no final para evitar corte no celular */
`;

const Tutorial = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
  align-items: center;
  text-align: center;

  :hover {
    background-color: ${purpleColor};
    color: white;
  }
`;

const ThumbnailImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TutorialTitle = styled.h1`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: ${purpleColor};
  text-transform: none;
  white-space: normal;
`;

const ReadMoreLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-weight: 600;
  text-transform: none;
  padding: 12px 100px;
  border-radius: 4px;
  background-color: ${purpleColor};
  transition: transform 500ms linear;
  margin-top: 12px;
  display: inline-block;
  text-align: center;

  :hover {
    transform: scale(1.05);
  }
`;

const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ModalContent = styled.div`
  background-color: ${theme.colors.black};
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: ${purpleColor};
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const MobileSpace = styled.div`
  height: 50px; /* Altura do espaço extra para evitar corte no celular */
  width: 100%;
`;

Modal.setAppElement("#root");
