import React, { useState, useEffect } from "react";
import {
  Container,
  Main,
  MinesField,
  Board,
  IdentifySignal,
  Loading,
  WaitButton,
  Counter,
  ResultCounter,
} from "./styles";
import LoadingIcon from "../../../assets/mines/loading.gif";
import Signal from "../../../components/Signal";
import Square from "../../../components/MinesTeste/Square";
import Navbar from "../../../components/Navbar";
import FooterMenu from "../../../components/Menu";
import IframeComponent from "../../../components/Mines/Iframe";

export default function Mines() {
  const [signalInputTitle, setSignalInputTitle] = useState("Sinal Encontrado!");
  const [protecoes, setProtecoes] = useState("--");
  const [minas, setMinas] = useState("--");
  const [validade, setValidade] = useState("--");

  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(false);
  const [countdown, setCountdown] = useState(2);

  const [vetor, setVetor] = useState([
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    let countdownTimer;
    if (wait) {
      countdownTimer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setWait(false);
      setProtecoes("--");
      setMinas("--");
      setValidade("--");
      setVetor([
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ]);
      setCounterValue(0);
    }

    return () => clearTimeout(countdownTimer);
  }, [wait, countdown]);

  const generateNumbers = () => {
    setLoading(true);

    const zerarVetor = [
      0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
      0, 0, 0, 0, 0,
    ];

    setVetor(zerarVetor);
    setProtecoes("--");
    setMinas("--");
    setValidade("--");

    setSignalInputTitle("Executando IA");

    setTimeout(() => {
      setSignalInputTitle("Conectando aos servidores");
    }, 3000);

    setTimeout(() => {
      setSignalInputTitle("Analisando Parâmetros");
    }, 6000);

    setTimeout(() => {
      setSignalInputTitle("Investigando dados");
    }, 8000);
    setTimeout(() => {
      setSignalInputTitle("Gerando resultado...");
    }, 9000);
    setTimeout(() => {
      setSignalInputTitle("Sinal Encontrado!");
    }, 12000);

    const minMine = 2;
    const maxMine = 3;

    const minStar = 3;
    const maxStar = 4;

    const numStars = Math.floor(Math.random() * (maxStar - minStar + 1)) + minStar;

    const numMine = Math.floor(Math.random() * (maxMine - minMine + 1)) + minMine;

    // Gerar um número aleatório entre 10 e 250 para o valor máximo do contador
    const randomMaxValue = Math.floor(Math.random() * (250 - 10 + 1)) + 10;

    const drawn = [];
    while (drawn.length < numStars) {
      const randomNumber = Math.floor(Math.random() * 25);
      if (!drawn.includes(randomNumber)) {
        drawn.push(randomNumber);
      }
    }

    const newVetor = [
      1, 1, 1, 1, 1, 
      1, 1, 1, 1, 1, 
      1, 1, 1, 1, 1, 
      1, 1, 1, 1, 1, 
      1, 1, 1, 1, 1,
    ];

    if (numStars === 3) {
      newVetor[drawn[0]] = 2;
      newVetor[drawn[1]] = 2;
      newVetor[drawn[2]] = 2;
    } else if (numStars === 4) {
      newVetor[drawn[0]] = 2;
      newVetor[drawn[1]] = 2;
      newVetor[drawn[2]] = 2;
      newVetor[drawn[3]] = 2;
    }

    const dataAtual = new Date();
    const hora = dataAtual.getHours();
    const minutos = dataAtual.getMinutes();
    const vali = minutos + 2;

    setTimeout(() => {
      setVetor(newVetor);
      setLoading(false);
      setProtecoes("Até 02");
      setMinas(`${numMine}`);
      setValidade(`${hora}:${vali < 10 ? `0${vali}` : vali}`);
      setWait(true);
      setCountdown(120); // Reset the countdown to 120 after completing the process
      setCounterValue(0); // Reset the counter value to 0
      setMaxCounterValue(randomMaxValue); // Set the random max counter value
    }, 12000);
  };

  // Counter functionality
  const [counterValue, setCounterValue] = useState(0);
  const [maxCounterValue, setMaxCounterValue] = useState(0);

  useEffect(() => {
    if (wait && counterValue < maxCounterValue) {
      const interval = setInterval(() => {
        setCounterValue((prevCounter) => prevCounter + 1);
      }, 200);
      return () => clearInterval(interval);
    }
  }, [wait, counterValue, maxCounterValue]);

  return (
    <Container>
      <Navbar title="Mines" />

      <Main>
        <Signal
          title={signalInputTitle}
          titleOutput="Nº de Minas"
          valueProtecao={protecoes}
          valueGame={minas}
          valueValidade={validade}
        />
        <Counter>{counterValue} PESSOAS FIZERAM ENTRADA</Counter>

        <MinesField>
          <Board>
            {vetor.map((element, index) => (
              <Square key={index} value={element} />
            ))}
          </Board>
        </MinesField>

        {wait ? (
          <WaitButton>
            Sinal Finalizado em {countdown} segundos
          </WaitButton>
        ) : (
          <IdentifySignal onClick={() => generateNumbers()}>
            {loading ? <Loading src={LoadingIcon} alt="" /> : "IDENTIFICAR ALGORITMO"}
          </IdentifySignal>
        )}
        <FooterMenu active="" />
        <IframeComponent />
      </Main>
   
    
    </Container>
  );
}
