import React, { useContext } from "react";
import { AuthContext } from "../context/auth";

import App from "./app.routes";
import Auth from "./auth.routes";

export default function Index() {

  const { email, loading } = useContext(AuthContext);
 
  if(loading){
    return <h1>Carregando...</h1>
  }
  return true ? <App /> : <Auth />;
}