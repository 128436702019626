import React from "react";
import styled from "styled-components";

export default function Signal({
  title,
  titleOutput,
  valueProtecao,
  valueGame,
  valueValidade,
}) {
  return (
    <>
      <SignalInput>
        <SignalInputTitle>{title}</SignalInputTitle>
        <ResultGroup>
          <Option>
            <OptionTitle>Proteções</OptionTitle>
            <OptionSubtitle>{valueProtecao}</OptionSubtitle>
          </Option>

          <Option>
            <OptionTitle>{titleOutput}</OptionTitle>
            <OptionSubtitle>{valueGame}</OptionSubtitle>
          </Option>
          <Option>
            <OptionTitle>Válido até</OptionTitle>
            <OptionSubtitle>{valueValidade}</OptionSubtitle>
          </Option>

        </ResultGroup>
      

      </SignalInput>

      <SignalExit></SignalExit>
    </>
  );
}

const SignalInput = styled.div`
  background-color: rgb(32, 32, 36);
  display: flex;
  width: 90%;
  border-radius: 7px;
  flex-direction: column;
`;
const SignalInputTitle = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 10px;

  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 7px;
`;

const ResultGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Option = styled.div`
  background: rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin-bottom: 10px;
  width: calc(90% / 3);
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    width: 30%;
    margin: 0 3px;
  }
`;

const OptionTitle = styled.h2`
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-bottom: 3px;
`;
const OptionSubtitle = styled.h5`
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  white-space: normal;
  color: #fff;
`;

const Result = styled.p`
margin-top: 5px;
text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-bottom: 5px;
`;

const SignalExit = styled.div`
  background-color: rgb(32, 32, 36);
  display: flex;
  width: 90%;
  border-radius: 7px;
  flex-direction: column;
`;
