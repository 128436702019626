import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import { AiOutlineMenu, AiOutlineHome, AiFillGamepad } from "react-icons/ai"; // Importe AiFillGamepad para representar "Games"
import { IoMdSchool } from "react-icons/io"; // Importe IoMdSchool para representar "Tutorial"
import { HiDownload } from "react-icons/hi";
import { FaGamepad } from "react-icons/fa"
import { Link } from "react-router-dom";

export default function Menu({ active }) {
  return (
    <Navbar>
      <NavContainer>
        <NavItem to="/home" active={active === "inicio"}>
          <FaGamepad size={24} />
          <NavItemTitle active={active === "inicio"}>Games</NavItemTitle>
        </NavItem>
        <NavItem to="/bonus" active={active === "baixar"}>
          <HiDownload size={24} />
          <NavItemTitle active={active === "baixar"}>Baixar</NavItemTitle>
        </NavItem>
      </NavContainer>
    </Navbar>
  );
}

const Navbar = styled.nav`
  position: fixed;
  bottom: 0.5rem;
  width: 100%; 
  max-width: 768px;
  border-radius: 25px;
  backdrop-filter: blur(5px);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  @media (max-width: 768px) {
    padding: 0rem 5rem 0rem 5rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 360px; /* Largura máxima do conteúdo */
`;

const NavItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.active ? "#8A3FFC" : "#d2d2d2")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

const NavItemTitle = styled.h5`
  font-size: 12px;
  margin-top: 4px;
`;
