import styled from "styled-components";


export const IframeContainer = styled.div`
  margin-top: 35px;
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const IframeComponentWrapper = styled.div`
  overflow: hidden; /* Esconde as barras de rolagem */
  width: 700px; 
  height: 550px; /* Altura do iframe */
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 90%;
  overflow: hidden; /* Esconde as barras de rolagem */
  border: none; 
  border-radius: 8px; /* Adicione bordas arredondadas, se desejar */
  box-shadow: -2px 4px 3px rgba(23, 23, 23, 0.2);
`;
