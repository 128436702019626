import React, { useState, useRef } from "react";
import {
  MobileContainer,
  MobileMain,
  SubtitleLogin,
  LoginButtonContainer,
  LoginButton,
  AccessTitle,
  Subtitle,
  BlinkingText1,
  BlinkingText2,
  ButtonVoltar,
  SignupLink
} from "./styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/Navbar";
import IframeComponent from "../../components/Cadastro_Iframe/Cadastro/iframe";

import logoImage from "../../assets/Icons/logo-estrela.png"; // Importe a imagem correta

export default function Page_Android() {
  const [star, setStar] = useState(true);
  const scrollToRef = useRef(null);

  const handleImageClick = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <MobileContainer>
      <Navbar title="" />
      {star ? (
        <LoginButtonContainer>
          <AccessTitle>ACESSO LIBERADO!</AccessTitle>

          <Subtitle>
            <BlinkingText1>NOSSOS SINAIS SÓ FUNCIONAM NA</BlinkingText1>{" "}
            <BlinkingText2>ESTRELA.BET</BlinkingText2>
          </Subtitle>
          <img
            src={logoImage}
            alt="Logo"
            style={{ margin: "50px", cursor: "pointer" }}
            onClick={() => setStar(false)}
          />

          <LoginButton onClick={() => setStar(false)}>CRIAR CONTA</LoginButton>
          <Link to="/tops">
            <LoginButton>JÁ POSSUO CONTA</LoginButton>
          </Link>
        </LoginButtonContainer>
      ) : null}

      <MobileMain>
        {star ? null : (
          <>
            <ButtonVoltar to="/Pos_Login"> {/* Use o to para redirecionar */}
              <FontAwesomeIcon icon={faArrowLeft} /> Voltar
            </ButtonVoltar>
            <IframeComponent />
          </>
        )}

        <div ref={scrollToRef}>
          {star ? null : (
            <SubtitleLogin>
              Conseguiu criar a conta? <SignupLink to="/tops">Entrar Agora</SignupLink>
            </SubtitleLogin>
          )}
        </div>
      </MobileMain>
    </MobileContainer>
  );
}
