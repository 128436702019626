import React, {useContext, useState} from "react";
import styled from "styled-components";
import { IoMdNotificationsOutline } from "react-icons/io";
import {FaUserCircle} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import theme from "../../styles/theme";
import { AuthContext } from "../../context/auth";
import LogoImage from "../../assets/Logo_SLOT_STARS_SEM_FUNDO.png"; // Certifique-se de que o caminho esteja correto
export default function Navbar({title}) {

  const {email, logoff} = useContext(AuthContext);
  const navigate = useNavigate(); // Inicialize useNavigate
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  const handleSignOut = () => {
    handleLogout(); // Faz logout
    navigate("/Login"); // Redireciona para a página de login
  };
  
  return (
    <>
    
    <Container>
    <LogoLink to="/home">
          <Logo src={LogoImage} alt="Logo" />
  
        </LogoLink>
    {/*   <GroupButtons>
        <Link to="/notification">
          <IoMdNotificationsOutline color="#fff" size={32} />
        </Link>

        <ButtonProfile style={{marginLeft: 15}} onClick={()=>setActiveSubMenu(!activeSubMenu)}>
          <FaUserCircle color="#D8D8D8" size={32} />
        </ButtonProfile>
      </GroupButtons> */}
    </Container>

    {
  activeSubMenu ? (
    <SubMenu>
      <Email>{email}</Email>
      <Logout onClick={() => {
        logoff(); // Executa o logoff
        navigate("/Login"); // Redireciona para a página de login
      }}>
        Encerrar sessão
      </Logout>
    </SubMenu>
  ) : null
}
    

     </>
  );
}

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Logo = styled.img`
  width: 150px; /* Ajuste o tamanho da logo conforme necessário */
  height: auto;

`;
const Container = styled.div`
  padding: 0px 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: sticky;
  top: 0px;
  left: auto;
  right: 0px;
  z-index: 11;
  padding: 0px 16px;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.1);
  border-right-color: rgba(255, 255, 255, 0.1);
  border-left-color: rgba(255, 255, 255, 0.1);
  -webkit-box-pack: center;
  justify-content: center;
  height: 67px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.black};
`;

const Title = styled.h1`
  color: #fff;
`;

const GroupButtons = styled.div`
  
`;

const ButtonProfile = styled.button`
background-color: transparent;
border: none;

`;

const SubMenu = styled.div`
display: flex;
right: 5px;
width: 200px;
height: 100px;
background-color: ${theme.colors.primary};
position: fixed;
margin-top: -12px;
z-index: 12;
border: 2px solid #000;
border-radius: 10px;
align-items: center;
padding: 5px 0px;
flex-direction: column;

`;

const Email = styled.span`

flex: 1;
width: 100%;
border-bottom: 1px solid #000;
padding: 5px;
display: flex;
align-items: center;
color: #d6d6d6;
`;

const Logout = styled.button`
flex: 1;
width: 100%;
background-color: transparent;
border: none;
color: red;
font-weight: 500;
transition: 500ms linear;

&:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
`;