import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  
  display: flex;
  flex-direction: column; 
  width: 100%;
  height: 100%;
  background-color: #1B2026;
  @media (max-width: 768px) {
    height: 100%;
  }
`;


export const Main = styled.div`
max-width: 700px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${theme.colors.primary};
`;

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 800px;
  max-width: 800px;
  max-height: 100%;
  overflow: auto;

  iframe {
    width: 100%;
    height: 200%;
    border: none;
  }
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

export const BannerImg = styled.img`
  margin-bottom: 24px;
  align-items: center;
  max-width: 700px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: 30%;
  }
`;

export const ProPlan = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  -webkit-box-align: left;
  padding: 16px;
  flex-direction: column;

 

  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ProPlanTitle = styled.h1`
  display: flex;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #7b1b93;
  text-transform: none;
  white-space: normal;
  align-items: center;
`;

export const ProPlanSubtitle = styled.p`
  margin-bottom: 24px;
  margin-top: 0px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-top: 24px;
`;

export const ProPlanButton = styled.button`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  text-transform: none;
  box-shadow: none;
  color: white;
border-radius: 4px;

  transition: 500ms linear;

:hover {
  transform: scale(1.1);
}
`;

export const ProPlanButtonTitle = styled.span`
flex:1;
background-color: ${theme.colors.button};
padding: 0.6rem 1.5rem;
border-radius: 4px;


`;

export const Games = styled.div`
  width: 40%;
  padding: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GroupGames = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 500ms linear;

  :hover {
    transform: scale(1.1);
  }
`;

export const GamesTitle = styled.h1`
  display: flex;
  padding: 10px 0 10px 0;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 1.5;
 
`;

export const GamesTitleIcon = styled.div`
  display: flex;
  background: #7b1b93;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const DownloadApps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 5px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Apps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  transition: 500ms linear;
  :hover {
    transform: scale(1.1);
  }
`;

export const App = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.black};
  width: 120px;
  color: white;
  font-size: 16px;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
`;
