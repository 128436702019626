// styles.js
import styled from "styled-components";
import theme from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.primary};;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Main = styled.div`
 display: flex;
  margin-top: 22px;
  max-width: 700px;
  align-items: center;
  justify-content: center;  
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-top: 85%;
    padding-bottom: 50px;
    width: 100%;
  }
`;

export const GameDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 105%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5); /* Efeito de sombra ofuscada */
  height: 100%;
  padding: 1px;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  background-color: #18181b;

  @media (max-width: 768px) {
    /* Espaçamento negativo para aproximar os containers */
   width: 90%;
    margin-right: -30px;
    margin-left: 20px;
    margin-bottom: -20px;
  }
`;

export const GameInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    /* Espaçamento negativo para aproximar os containers */
   width: 50%;
  }
`;

export const GameImage = styled.img`
  width: 100%;
  max-height: 150px;
  flex: flex;
  margin-bottom: 0px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    /* Espaçamento negativo para aproximar os containers */
    margin-right: -10px;
  
  }

`;

export const ProgressBar = styled.div`
  width: 96%;
  height: 20px;
  background-color: white;
  border-radius: 10px;

  position: relative;
`;

export const ProgressFill = styled.div`
  height: 100%;
  border-radius: 10px;
  width: ${({ percentage }) => percentage}%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  background-color: ${({ percentage }) =>
    percentage >= 70 ? "green" : percentage >= 60 ? "#DAA520" : "red"};
`;

export const PercentageText = styled.span`
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

export const TitleEstrategy = styled.h2`
  font-size: 18px;
  font-weight: bold;
  justify-content: center;

  color: white;
  margin: 4px 0;
  
`;

export const StrategyContainer = styled.div`
  width: 100%;
  height: 30px;
  align-items: center;
  background-color: ${({ percentage }) =>
    percentage >= 70 ? "green" : percentage >= 60 ? "#DAA520" : "red"};
  border-radius: 7px;
  margin-bottom: 10px;
  text-align: center;
  /* Centralizar o conteúdo horizontalmente */
  display: flex;
  /* Adicionado display flex */
 
  /* Centralizar verticalmente */
  justify-content: center; /* Centralizar horizontalmente */
`;

export const Strategy = styled.p`
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  color: white;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  margin-top: 30px;
`;

export const Stars = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StarIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

export const PlayButton = styled.button`
  background-color: ${theme.colors.button};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 5px;

  :hover {
    background-color: ${theme.colors.buttonHover};
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1.2px;
  background-color: white;
  margin: 8px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 1px;
`;

export const IdentifySignal = styled.button`
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: ${theme.colors.button};

  transition: 10ms;

  &:hover {
    opacity: 0.5;
  }
`;

export const WaitButton = styled.div`
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: ${theme.colors.button};
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Counter = styled.p`
  display: flex;
  margin-top: 12px;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-bottom: 5px;
`;

export const ResultCounter = styled.p`
  /* Add your styles for the ResultCounter here */
  /* For example:
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  */
`;
export const GroupGames = styled.div`
  grid-template-columns: repeat(3, 1fr); /* Layout 3x3 para dispositivos maiores */
  gap: 20px; /* Espaçamento entre os jogos */
  max-width: 900px;
  margin: 20px auto; /* Centralizar os jogos */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 500ms linear;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Layout 2x2 para dispositivos móveis */
    gap: 30px; /* Espaçamento maior para dispositivos móveis */
    width: 100%;
  }


`;