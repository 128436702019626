import styled from "styled-components";

export const IframeContainer = styled.div`
 width: 100%;
`;

export const IframeComponentWrapper = styled.div`
  overflow: hidden; /* Esconde as barras de rolagem */
  height: 500px; /* Altura do iframe */
`;

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  overflow: hidden; /* Esconde as barras de rolagem */
  border: none; 
  border-radius: 8px; /* Adicione bordas arredondadas, se desejar */
  box-shadow: -2px 4px 3px rgba(23, 23, 23, 0.2);
`;
