import { useState, createContext, useContext, useEffect } from "react";
import api from "../config/api";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
/*   const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem("emailStorage");

      if (storageUser) {
        setEmail(storageUser);
      }
    }

    loadStorage();
  }, []);

  async function signIn(email) {
    setLoading(true);
    await api
      .post("users/login", {
        email: email,
      })
      .then((response) => {
        setEmail(response.data.email);
        localStorage.setItem("emailStorage", email);
        toast.success("Seja bem-vindo!");
        window.location.href = "/";
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  async function signUp(email, name, phone_number) {
    setLoading(true);
    await api
      .post("users/", {
        email: email,
        name: name,
        phone_number: phone_number,
      })
      .then((response) => {
        setEmail(response.data.email);
        localStorage.setItem("emailStorage", email);
        toast.success("Cadastro realizado!");
        window.location.href = "/Pos_Login";
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  async function logoff() {
    localStorage.removeItem("emailStorage");
    setEmail(null);
  } */

  return (
    <AuthContext.Provider value={{ /* signIn, signUp, logoff, email, loading  */}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
