import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Bonus from "../pages/Bonus";
import Tops from "../pages/Tops";
import Login from "../pages/Login";
import Checkout from "../pages/Checkout";
import Page_Ios from "../pages/Page_Ios";
import Page_Android from "../pages/Page_Android";
import Pos_Login from "../pages/Pos_Login";
import Notification from "../pages/Notification";
import Error from "../pages/Error";

import Monkey from "../pages/Games/FortuneTigers";
import FortuneOx from "../pages/Games/FortuneOX";
import FortuneTigers from "../pages/Games/FortuneTigers";
import FortuneRabbit from "../pages/Games/FortuneRabbit";
import FortuneMouse from "../pages/Games/FortuneMouse";
import IceScape from "../pages/Games/IceScape";
import DragonHatch from "../pages/Games/DragonHatch";
import DoubleFortune from "../pages/Games/DoubleFortune";
import HipHopPanda from "../pages/Games/HipHopPanda";
import CandyBonanza from "../pages/Games/CandyBonanza";
import CryptoGold from "../pages/Games/CryptoGold";
import GeneshaGold from "../pages/Games/GeneshaGold";
import PiggyGold from "../pages/Games/PiggyGold";
import Mines from "../pages/Games/MinesTeste"
import NinjaCrash from "../pages/Games/NinjaCrashTeste";
import FortuneDragon from "../pages/Games/FortuneDragon";


export default function AppRoutes() {
  return (
    
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/home" element={<Home />} />
        <Route index path="/bonus" element={<Bonus />} />
        <Route index path="/tops" element={<Tops />} />
        <Route index path="/Page_Ios" element={<Page_Ios />} />
        <Route index path="/Page_Android" element={<Page_Android />} />
        <Route index path="/Pos_Login" element={<Pos_Login />} />

        {/* Jogos */}
        <Route index path="/monkey" element={<Monkey />} />
        <Route index path="/FortuneTigers" element={<FortuneTigers />} />
        <Route index path="/FortuneOx" element={<FortuneOx />} />
        <Route index path="/FortuneRabbit" element={<FortuneRabbit />} />
        <Route index path="/FortuneMouse" element={<FortuneMouse />} />
        <Route index path="/IceScape" element={<IceScape />} />
        <Route index path="/DragonHatch" element={<DragonHatch />} />
        <Route index path="/DoubleFortune" element={<DoubleFortune />} />
        <Route index path="/HipHopPanda" element={<HipHopPanda />} />
        <Route index path="/CandyBonanza" element={<CandyBonanza />} />
        <Route index path="/CryptoGold" element={<CryptoGold />} />
        <Route index path="/GeneshaGold" element={<GeneshaGold />} />
        <Route index path="/PiggyGold" element={<PiggyGold />} />
        <Route index path="/Mines" element={<Mines />} />
        <Route index path="/NinjaCrash" element={<NinjaCrash />} />
        <Route index path="/fortuneDragon" element={<FortuneDragon />} />

        <Route index path="/tops" element={<Tops />} />
        <Route index path="/notification" element={<Notification />} />
        <Route index path="/checkout" element={<Checkout />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

  );
}
