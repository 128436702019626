import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Main,
  IdentifySignal,
  Loading,
  WaitButton,
  Counter,
  LogoImage,
} from "./styles";
import LoadingIcon from "../../../assets/mines/loading.gif";
import Signal from "../../../components/Signal/signalNinja";
import Navbar from "../../../components/Navbar";
import Logo from "../../../assets/ninjaLoader.webp";
import IframeComponent from "../../../components/NinjaCrash/Iframe";
import { Board, MinesField } from "../../../components/MinesRefactor/styles";
import { FruitContainer, Fruit } from "./styles";

import FooterMenu from "../../../components/Menu";

import Bolo from "../../../assets/ImgNinja/Doces/bolo.png"
import Brigadeiro from "../../../assets/ImgNinja/Doces/brigadeiro.png"
import CasquinhaBranca from "../../../assets/ImgNinja/Doces/casquinha_branco.png"
import CasquinhaChocolate from "../../../assets/ImgNinja/Doces/casquinha_chocolate.png"
import ChapeuDeCego from "../../../assets/ImgNinja/Doces/chapeudecego.png"
import Geleia from "../../../assets/ImgNinja/Doces/geleia.png"
import Pastel from "../../../assets/ImgNinja/Doces/pastel.png"
import Picole from "../../../assets/ImgNinja/Doces/picole.png"
import Pirulito from "../../../assets/ImgNinja/Doces/pirulito.png"
import Rosquinha from "../../../assets/ImgNinja/Doces/rosquinha.png"
import Torta from "../../../assets/ImgNinja/Doces/torta.png"

export default function Aviator() {
  // COM ERRO [CASQUINHA BRANCA, PICOLE, CASQUINHA CHOCOLATE, ]
  const FRUIT_IMAGES = [Bolo, Brigadeiro, ChapeuDeCego, Geleia, Pastel, Pirulito, Rosquinha, Torta, CasquinhaBranca, CasquinhaChocolate, Picole];

  const [signalInputTitle, setSignalInputTitle] = useState("Sinal Encontrado!");
  const [cortes, setCortes] = useState("--");
  const [saida, setSaida] = useState("--");
  const [validade, setValidade] = useState("--");
  const [fruits, setFruits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [counterValue, setCounterValue] = useState(0);
  const [maxCounterValue, setMaxCounterValue] = useState(0);
  const restartTime = 60000;
  const countdownTimerRef = useRef(null);

  useEffect(() => {
    if (wait && countdown > 0) {
      countdownTimerRef.current = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setWait(false);
      clearTimeout(countdownTimerRef.current);
      setCortes("--");
      setSaida("--");
      setValidade("--");
      resetSignal();
    }
    return () => clearTimeout(countdownTimerRef.current);
  }, [wait, countdown]);

  const generateFruits = (cortes) => {
    const generatedFruits = [];
    for (let i = 0; i < cortes; i++) {
      const randomFruitImage = FRUIT_IMAGES[Math.floor(Math.random() * FRUIT_IMAGES.length)];
      generatedFruits.push(randomFruitImage);
    }
    setFruits(generatedFruits);
  };

  useEffect(() => {
    generateFruits(cortes);
  }, [cortes]);

  const resetSignal = () => {
    setSignalInputTitle("Sinal Encontrado!");
    setCounterValue(0);
    setCountdown(60);
    setTimeout(() => {
      setWait(false);
      setCortes("--");
      setSaida("--");
      setValidade("--");
    }, restartTime);
  };

  const generateNumbers = () => {
    if (wait) return;

    setLoading(true);
    setCortes("--");
    setSaida("--");
    setValidade("--");

    setSignalInputTitle("Executando IA");
    setTimeout(() => {
      setSignalInputTitle("Conectando aos servidores");
    }, 3000);
    setTimeout(() => {
      setSignalInputTitle("Analisando Parâmetros");
    }, 6000);
    setTimeout(() => {
      setSignalInputTitle("Investigando dados");
    }, 8000);
    setTimeout(() => {
      setSignalInputTitle("Gerando resultado...");
    }, 9000);
    setTimeout(() => {
      setSignalInputTitle("Sinal Encontrado!");
    }, 12000);

    const min = 1.4;
    const max = 2.1;
    const randomNumber = (Math.random() * (max - min) + min).toFixed(2);
    const dataAtual = new Date();
    const hora = dataAtual.getHours();
    const minutos = dataAtual.getMinutes();
    const vali = minutos + 2;

    setTimeout(() => {
      setSaida(randomNumber);
      setLoading(false);
      setCortes(getRandomNumber(6));
      setValidade(`${hora}:${vali < 10 ? `0${vali}` : vali}`);
      setWait(true);
      setCounterValue(0);
      setMaxCounterValue(getRandomNumber(250));
    }, 12000);
  };

  const getRandomNumber = (max) => {
    return Math.floor(Math.random() * max) + 1;
  };

  useEffect(() => {
    if (wait && counterValue < maxCounterValue) {
      const interval = setInterval(() => {
        setCounterValue((prevCounter) => Math.min(prevCounter + getRandomNumber(5), maxCounterValue));
      }, 200);
      return () => clearInterval(interval);
    }
  }, [wait, counterValue, maxCounterValue]);

  return (
    <Container>
      <Navbar title="FruitCrash" />

      <Main>
        <LogoImage src={Logo} alt="Logo" />
        <Signal
          title={signalInputTitle}
          titleOutput="Saida"
          valueProtecao={cortes}
          valueGame={saida}
          valueValidade={validade}
          cortes={cortes}
        />
        <Counter>{counterValue} PESSOAS FIZERAM ENTRADA</Counter>

        <MinesField>
          <Board>
            <FruitContainer>
              {fruits.map((fruitImage, index) => (
                <Fruit key={index} style={{ animationDelay: `${index * 0.2}s` }}>
                  <img src={fruitImage} alt={`Fruit ${index}`} />
                </Fruit>
              ))}
            </FruitContainer>
          </Board>
        </MinesField>

        {wait ? (
          <WaitButton>Sinal Finaliza em {countdown} segundos</WaitButton>
        ) : (
          <IdentifySignal onClick={generateNumbers}>
            {loading ? (
              <Loading src={LoadingIcon} alt="" />
            ) : (
              "IDENTIFICAR ALGORITMO"
            )}
          </IdentifySignal>
        )}
        <FooterMenu active="" />

        <IframeComponent />

      </Main>
    </Container>
  );
}
