import React, { useState } from 'react';
import { IframeContainer, IframeComponentWrapper, StyledIframe } from './styles';

function IframeComponent() {
  const [iframeUrl, setIframeUrl] = useState(
    'https://external.apostaganha.bet/#/cassino/mines'
  );

  const handleNewGameClick = () => {
    // Change the iframe URL to the new game URL
    setIframeUrl('https://external.apostaganha.bet/#/cassino/mines');
  };

  return (
    <IframeContainer>
      <IframeComponentWrapper>
        <StyledIframe
          src={iframeUrl}
          title="Mines"
        ></StyledIframe>
      </IframeComponentWrapper>
    </IframeContainer>
  );
}

export default IframeComponent;