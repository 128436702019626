import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';
import { Link } from 'react-router-dom';


export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.primary};
  padding-bottom: 30px;
  @media (max-width: 768px) {
    height: 100%;
    margin-top: -35px
  }
`;

export const Banner = styled.img`
width: 100%;
max-width: 700px;
height: 250px;
margin: 10px;

 @media (max-width: 768px) {
    width: 100%;
    height: 150px;
  }
`;

export const Main = styled.div`
  display: flex;
  margin-top: 22px;
  padding-bottom: 20px;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding-bottom: 20px;
  overflow-y: auto; 
`;

export const Body = styled.div`
max-width: 700px;
display: flex;
width: 95%;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: rgba(0, 0, 0, 0.3);
border-radius: 10px;


`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: white;
  margin-bottom: -10px;
  margin-top: 15px;
`;

export const GroupGames = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Layout 3x3 para dispositivos maiores */
  gap: 20px; /* Espaçamento entre os jogos */
  max-width: 700px;
  width: 700px;
  margin: 20px 20px 80px 20px; /* Centralizar os jogos */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 500ms linear;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Layout 2x2 para dispositivos móveis */
    gap: 15px; /* Espaçamento maior para dispositivos móveis */
    max-width: 650px; /* Largura máxima para dispositivos móveis */
    padding: 0 10px;
  }

`;

export const Games = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
`;

export const GamesLinkContainer = styled.div`
  display: flex;
  margin: 20px;
  transition: 500ms linear;

  @media (hover: hover) {
    :hover {
      transform: scale(1.1);
    }
  }
`;

export const GameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  background-color: #18181b;
box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5); /* Efeito de sombra ofuscada */
  @media (max-width: 768px) {
  
  }

  @media (hover: hover) {
    :hover {
      transform: scale(1.1);
    }
  }
`;


export const GamesLink = styled.img`
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`;

export const GameTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 10px 0;
`;
export const TitleEstrategy = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 10px 0;
`;

export const ProgressBar = styled.div`
  width: 96%;
  height: 20px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
`;

export const move = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 20px 0;
  }
`;

export const ProgressFill = styled.div`
  height: 100%;
  border-radius: 10px;
  background-image: linear-gradient(90deg, transparent 50%, rgba(0, 0, 0, 0.1) 50%);
  background-size: 20px 20px;
  animation: ${move} 0.5s linear infinite;
  width: ${({ percentage }) => percentage}%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  background-color: ${({ percentage }) =>
    percentage >= 70
      ? 'green'
      : percentage >= 60
        ? '#DAA520	'
        : 'red'};
`;

export const PercentageText = styled.span`
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

export const Stars = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StarIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

export const Strategy = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

export const PlayButton = styled.button`
  background-color: ${theme.colors.button};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 5px;

  :hover {
    background-color: ${theme.colors.buttonHover};
  }
`;

export const DemoButton = styled.button`
  background-color: ${theme.colors.button};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;

  :hover {
    background-color: ${theme.colors.buttonHover};
  }
`;

export const GameInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
      transform: scale(1.05);
    }
`;

export const GameImage = styled.img`
  width: 100%;
  max-height: 200px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1.2px;
  background-color: white;
  margin: 8px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;
export const StrategyContainer = styled.div`
  width: 88%;
  height: 30px;
  background-color: ${({ percentage }) =>
    percentage >= 70 ? 'green' : percentage >= 60 ? '#DAA520' : 'red'};
  border-radius: 7px;
  margin-bottom: 10px;
  text-align: center; /* Centralizar o conteúdo horizontalmente */
  display: flex; /* Adicionado display flex */
  align-items: center; /* Centralizar verticalmente */
  justify-content: center; /* Centralizar horizontalmente */
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // fundo escurecido com semi-transparência
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; // Organiza os filhos em uma coluna
  z-index: 1000; // Garante que o modal fique acima de outros conteúdos
  padding: 40px; // Adiciona espaço em torno dos elementos para evitar colagem nas bordas
`;

export const ModalContainer = styled.div`
  position: relative;
  padding: 0px;
  padding-bottom: 0px; // Aumenta o padding inferior para dar espaço ao botão
  background: white;
  border-radius: 8px;
  border: 5px solid #ff6600; // Alterado para o código hexadecimal específico
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 95%;
  height: 75%;
  max-width: 600px;
  overflow-x: hidden; // Impede rolagem horizontal
  overflow-y: hidden; // Permite rolagem vertical quando necessário
`;


export const CloseButton = styled.button`
  background-color: #ff6600; // Cor laranja
  color: white; // Texto branco para contraste
  border: none;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 95%; 
  height: 50px; 
  margin-top: 20px; // Espaço acima do botão para separar do modal
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: block; // Garantir que o botão seja um bloco para centrar facilmente
  margin-left: auto;
  margin-right: auto; // Centraliza o botão horizontalmente

  @media (min-width: 768px) { // Estilos para telas maiores que 768px (desktops)
    width: 41%; // Torna o botão mais estreito em desktop
    font-size: 14px; // Diminui um pouco o tamanho da fonte
    padding: 8px 16px; // Ajuste leve no padding
  }
`;
