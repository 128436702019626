import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LogoAposta from "../../assets/aposta.jpg";
import "./style.css";
import { ModalOverlay, ModalContainer, ModalContent, CloseButton } from './styles';
import {
  Container,
  Banner,
  Title,
  Main,
  GroupGames,
  GameContainer,
  GameTitle,
  Stars,
  StarIcon,
  Strategy,
  PlayButton,
  GameInfoContainer,
  GameImage,
  ProgressBar,
  ProgressFill,
  PercentageText,
  LineSeparator,
  TitleEstrategy,
  ButtonsContainer,
  StrategyContainer,
  Games,
  Body,
} from "./styles";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import CardHomeAlert from "../../components/CardHomeAlert";
import Menu from "../../components/Menu";

// Import the images of the games
import Ox from "../../assets/JogosSlot/fortune_ox.webp";
import Tigers from "../../assets/JogosSlot/fortune_tiger.webp";
import Star from "../../assets/star.png";
import Coelho from "../../assets/JogosSlot/fortune_rabbit.webp";
import FortuneMouse from "../../assets/JogosSlot/fortune_mouse.webp";
import FortuneDragon from "../../assets/JogosSlot/pgsoft-fortune-dragon.webp";

// import SlidingName from '../../components/Pix/styles';
import Mines from "../../assets/JogosSlot/mines.webp";
import NinjaCrash from "../../assets/JogosSlot/ninjacrash.webp";
import Pix from "../../components/PixDiferente/Card";
import BannerAviso from "../../assets/BANNERNOVO.webp";

import BannerTeste from "../../assets/bannerTeste.jpeg";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const generateRandomStrategy = () => {
  const strategyOptions = ["Normal", "Turbo", "Auto"];

  const shuffledStrategies = shuffleArray([...strategyOptions]);

  const startIndex = Math.floor(Math.random() * 3);

  const selectedStrategies = [...shuffledStrategies];
  
  const initialStrategy = selectedStrategies.splice(startIndex, 1)[0];
  selectedStrategies.unshift(initialStrategy);

  const strategy1 = `${selectedStrategies[0]} ${Math.floor(Math.random() * 7) + 4
  }`;
  const strategy2 = `${selectedStrategies[1]} ${Math.floor(Math.random() * 7) + 4
  }`;
  const strategy3 = `${selectedStrategies[2]} ${Math.floor(Math.random() * 7) + 4
  }`;
  
  return [strategy1, strategy2, strategy3];
};

const generateRandomPercentage = () => {
  return Math.floor(Math.random() * 47) + 50;
};

const gamesList = [
  {
    id: 5,
    title: "Fortune Dragon",
    imageSrc: FortuneDragon,
    pagePath: '/fortuneDragon',
  },
  {
    id: 1,
    title: "Fortune Tiger",
    imageSrc: Tigers,
    pagePath: "/FortuneTigers",
  },
  
  {
    id: 2,
    title: "Fortune Ox",
    imageSrc: Ox,
    pagePath: "/FortuneOx",
  },
  
  {
    id: 3,
    title: "Fortune Rabbit",
    imageSrc: Coelho,
    pagePath: "/FortuneRabbit",
  },
  
  {
    id: 4,
    title: "Fortune Mouse",
    imageSrc: FortuneMouse,
    pagePath: "/FortuneMouse",
  },
  
  {
    id: 5,
    title: "Mines",
    imageSrc: Mines,
    pagePath: "/Mines",
  },
  {
    id: 5,
    title: "Ninja Crash",
    imageSrc: NinjaCrash,
    pagePath: '/NinjaCrash',
  },
];

const GamesList = () => {
  const [gameData, setGameData] = useState(() => {
    const storedGameData = localStorage.getItem("gameData");
    return storedGameData ? JSON.parse(storedGameData) : gamesList;
  });
  
  const [modalOpen, setModalOpen] = useState(false);
  const [appReady, setAppReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [linkGame, setLinkGame] = useState("");
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const channel = new BroadcastChannel("gameDataChannel");

    const updateGameData = () => {
      const updatedGameData = gameData.map((game, index) => {
        const strategies = generateRandomStrategy();

        return {
          ...game,
          key: index,

          strategy: strategies[0],
          strategy2: strategies[1],
          strategy3: strategies[2],
          percentage: generateRandomPercentage(),
        };
      });

      setGameData(updatedGameData);
      localStorage.setItem("gameData", JSON.stringify(updatedGameData));
      channel.postMessage(updatedGameData);
    };

    channel.onmessage = (event) => {
      setGameData(event.data);
    };

    if (!localStorage.getItem("gameData")) {
      updateGameData();
    }

    const interval = setInterval(() => {
      updateGameData();
    }, 300000);

    return () => {
      clearInterval(interval);
      channel.close();
    };
  }, []);

  const navigate = useNavigate();

  const handlePlayNowClick = () => {
    navigate(linkGame);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSourceFromUrl = urlParams.get('utm_source');
    if (utmSourceFromUrl) {
      localStorage.setItem('utm_source', utmSourceFromUrl);
    }
    setAppReady(true);
  }, []);
  
  const handlePlayNowClickNot = () => {
    if (!appReady) {
      console.log("A aplicação ainda está carregando, por favor aguarde.");
      return;
    }
  
    const urlParams = new URLSearchParams(window.location.search);
    const utmSourceFromUrl = urlParams.get('utm_source');
  
    const utmSource = utmSourceFromUrl || localStorage.getItem('utm_source') || 'aplicativo';
  
    const newUrl = `https://go.apostaganha.bet/visit/?bta=71579&brand=apostaganha&utm_campaign=${utmSource}`;
  
    setLinkGame(newUrl); // Armazena o link para uso no modal
    setIframeModalOpen(true); // Abre o modal que contém o iframe
  };
  
  const onClick = (link) => {
    setLinkGame(link)
    openModal()
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Container>
      <Navbar />
      <Main>
        <Banner src={BannerAviso} alt="" />
        <Pix />
        <Body>
          <CardHomeAlert />
          <Games>
            <GroupGames>
              {gameData.map((game, index) => (
                <button 
                  style={{ background: 'transparent', border: 'none' }}
                  onClick={() => onClick(game.pagePath)}
                  key={index}
                >
                  <GameInfoContainer>
                    <GameImage src={game.imageSrc} alt={game.title} />
                    {game.percentage !== undefined && (
                      <ProgressBar>
                        <ProgressFill percentage={game.percentage}>
                          <PercentageText>{game.percentage}%</PercentageText>
                        </ProgressFill>
                      </ProgressBar>
                    )}
                  </GameInfoContainer>
                </button>
              ))}
            </GroupGames>
          </Games>
        </Body>
        <Menu active="inicio" />
      </Main>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="modal-content">
          <h3>Você já possui conta na casa?</h3>
          <img className="modal-image" src={LogoAposta} alt="Logo aposta ganha" />
          <div className="modal-buttons">
            <button className="modal-button" onClick={handlePlayNowClick}>Sim</button>
            <button className="modal-button" onClick={handlePlayNowClickNot}>Não</button>
          </div>
        </div>
      </Modal>
      <IframeModal isOpen={iframeModalOpen} link={linkGame} onClose={() => setIframeModalOpen(false)} />
    </Container>
  );
};

const Modal = ({ isOpen, onClose, children }) => {

  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal" ref={modalRef}>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

const IframeModal = ({ isOpen, link, onClose }) => {
  const modalRef = useRef();
  

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer ref={modalRef}>
          <iframe src={link} frameBorder="0" style={{ width: '100%', height: '500px', overflowX: 'hidden', maxWidth: '100%' }} title="External Content"></iframe>
      </ModalContainer>
      <CloseButton onClick={onClose}>JOGAR AGORA</CloseButton>
    </ModalOverlay>
  );
};

export default GamesList;