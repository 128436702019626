import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  WelcomeImage,
  Title,
  Subtitle,
  InputContainer,
  IconContainer,
  Input,
  LoginButton
} from "./styles";

import { toast } from "react-toastify";
import Logo from "../../assets/Pagsbet-logo-att.png";
import { AuthContext } from "../../context/auth";
import { AiOutlineUser } from "react-icons/ai";
import { IoMdMail } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const { signUp } = useContext(AuthContext);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [allEmails, setAllEmails] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameInfo, setNameInfo] = useState("");
  const [emailError, setEmailError] = useState("");
  const [remainingTime, setRemainingTime] = useState(300); // 300 segundos = 5 minutos


  useEffect(() => {
    let timer;

    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      // O tempo acabou, execute alguma ação aqui, como desabilitar o botão de convite
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [remainingTime]);

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const cleanedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleanedValue.length <= 11) {
      const formattedValue = formatPhoneNumber(cleanedValue);
      setPhone(cleanedValue);
      setFormattedPhone(formattedValue);
      setPhoneError("");
    } else {
      setFormattedPhone(formattedPhone);
      setPhoneError("Número deve conter no máximo 11 dígitos");
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const match = phoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;

    if (/^[\p{L}\s]+$/u.test(inputValue)) {
      setName(inputValue);
      setNameError("");
      setNameInfo("");
    } else {
      setName("");
      setNameError("Nome deve conter apenas letras e espaços");
      setNameInfo("");
    }
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) {
      setEmail(inputValue);
      setEmailError("");
    } else {
      setEmail("");
      setEmailError("Email inválido");
    }
  };

  async function handleSignUp() {
    if (!name || !email || !phone) {
     toast.danger("Preencha os dados corretamente") 
      return;
    }

    if (
      validarEmail(email) &&
      formattedPhone.length === 15 &&
      phoneError === "" &&
      nameError === "" &&
      allEmails.indexOf(email) === -1 &&
      remainingTime > 0
    ) {

      signUp(email, name, formattedPhone);
      
      // Obtém o timestamp do servidor
  /*     await setDoc(doc(db, "emails", email), {
        nome: name,
        endereco: email,
        telefone: formattedPhone,
      }).then(() => {
        toast.success("Cadastro Salvo!");
        signIn(email);
        navigate("/Pos_Login");
      });
     */
    
    
    } else {
      if (emailError !== "") {
        alert("Email inválido.");
      } else if (formattedPhone.length !== 15) {
        alert("O número de telefone deve conter 11 dígitos.");
      } else if (nameError !== "") {
        alert("Nome inválido.");
      } else if (allEmails.indexOf(email) !== -1) {
        alert("Email já cadastrado.");
      } else if (remainingTime <= 0) {
        alert("Tempo esgotado para o convite.");
      } else {
        alert("Corrija os erros antes de avançar.");
      }
    }
  }

  function validarEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <>
      {/* Cronômetro no topo da página */}
      <div
        style={{
          background: "#8A3FFC", // Alterado para a cor roxa (purple)
          color: "white", // Alterado para a cor branca (white)
          padding: "10px",
          textAlign: "center",
          marginBottom: "10px",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999,
          animation: "blink 1s linear infinite", // Adicionado efeito de piscar
        }}
      >
         Você tem 🕐 {Math.floor(remainingTime / 60)}:
        {(remainingTime % 60).toString().padStart(2, "0")} para se cadastrar! 🔥
      </div>

      <Container>
        <WelcomeImage src={Logo} alt="Bet Logo" />
        <Title>CADASTRE-SE</Title>

        <InputContainer>
          <Input
            type="name"
            placeholder="Seu nome"
            onChange={handleNameChange}
          />
          <IconContainer>
            <AiOutlineUser color="#8A3FFC" size={20} />
          </IconContainer>
        </InputContainer>
        {nameError && <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>{nameError}</p>}
        {nameInfo && <p style={{ color: "green", fontSize: "14px", marginTop: "5px" }}>{nameInfo}</p>}

        <InputContainer>
          <Input
            type="email"
            placeholder="Seu e-mail"
            onChange={handleEmailChange}
          />
          <IconContainer>
            <IoMdMail color="#8A3FFC" size={20} />
          </IconContainer>
        </InputContainer>
        {emailError && <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>{emailError}</p>}

        <InputContainer>
          <Input
            type="tel"
            placeholder="DDD + WhatsApp"
            value={formattedPhone}
            onChange={handlePhoneChange}
          />
          <IconContainer>
            <FaWhatsapp color="#8A3FFC" size={20} />
          </IconContainer>
        </InputContainer>
        {phoneError && <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>{phoneError}</p>}

        <LoginButton onClick={() => handleSignUp()} disabled={remainingTime <= 0}>
          Avançar
        </LoginButton>
      </Container>
    </>
  );
}
