// styles.js
import styled, { css } from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1B2026;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  padding-bottom: 10px;
  align-items: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  width: 100%;
  height: calc(100% - 67px - 67px);
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding: 30px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Tamanho fixo para o quadrado dos passos */
  height: 30px; /* Tamanho fixo para o quadrado dos passos */
  border-radius: 4px;
  background-color: ${(props) => props.color || "#7B1B93"};
  margin-right: 15px;
  color: white;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    /* Aumentar o tamanho do ícone no celular */
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
`;

export const StepText = styled.p`
  font-size: 16px;
  color: white;
  margin: 0;
  display: flex;
  align-items: center;

  /* Adicionar estilo condicional para reduzir o tamanho da fonte */
  ${({ isLongText }) =>
    isLongText &&
    css`
      font-size: 14px;
    `}

  /* Adicionar estilo para verificar se o texto é longo com base no tamanho visual na tela */
  ${({ isLongText }) =>
    isLongText &&
    css`
      @media (min-width: 769px) {
        white-space: nowrap;
        max-width: 200px;
      }
    `}
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  margin-top: 30px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const MobileContainer = styled(Container)`
  min-height: 100%;
`;

export const MobileMain = styled(Main)`
  padding: 20px;
  max-width: 100%;
`;

export const MobileStepIcon = styled(StepIcon)`
  @media (max-width: 768px) {
    /* Aumentar o tamanho do ícone no celular */
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
`;

export const MobileStepText = styled(StepText)`
  font-size: 14px; /* Aumentar o tamanho do texto no celular */
  text-align: left;
`;
