import React from 'react';
import { IframeContainer, IframeComponentWrapper, StyledIframe } from './styles';

function IframeComponent() {
  return (
    <IframeContainer>
      <IframeComponentWrapper>
        <StyledIframe
          src=""
          title="Hip Hop Panda"
        ></StyledIframe>
      </IframeComponentWrapper>
    </IframeContainer>
  );
}

export default IframeComponent;