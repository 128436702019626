import React from 'react';
import { IframeContainer, IframeComponentWrapper, StyledIframe } from './styles';

function IframeComponent() {
  return (
    <IframeContainer>
      <IframeComponentWrapper>
        <StyledIframe
          src="
          https://external.apostaganha.bet/#/cassino/fortune0"
          title="Fortune OX"
        ></StyledIframe>
      </IframeComponentWrapper>
    </IframeContainer>
  );
}

export default IframeComponent;