// index.js
import React from 'react';
import { Container, Subtitle, Title, Main, Step, StepIcon, StepText, MobileContainer, MobileMain, MobileStepIcon, MobileStepText } from './styles';

import Navbar from '../../components/Navbar';
import Menu from "../../components/Menu";

export default function Page_Android() {
  const steps = [
    "Clique no ícone de 'Mais opções' no canto superior direito do aparelho",
    "Depois clique em 'Instalar Aplicativo' ou 'Adicionar à tela Inicial'",
    "Confirme a ação clicando em 'Instalar' no popup que aparece na tela",
  ];

  // Função para verificar se o texto é longo
  const isLongText = (text) => text.length > 35;

  return (
    <MobileContainer>
      <Navbar title="Instalação" />
      <MobileMain>
        <Title>
          Instalação <span style={{ color: "#7B1B93" }}>Android</span>
        </Title>
        <Subtitle>Siga os passos abaixo para instalar de maneira correta no seu smartphone:</Subtitle>

        {steps.map((step, index) => (
          <Step key={index}>
            <MobileStepIcon style={{ backgroundColor: "#7B1B93" }}>
              <MobileStepText isLongText={isLongText(step)}>{index + 1}</MobileStepText>
            </MobileStepIcon>
            <MobileStepText isLongText={isLongText(step)}>{step}</MobileStepText>
          </Step>
        ))}
      </MobileMain>
      <Menu active="baixar" />
    </MobileContainer>
  );
}
