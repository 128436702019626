// Spaceman.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Main,
  Title,
  GroupGames,
  GameContainer,
  GameTitle,
  Stars,
  StarIcon,
  Strategy,
  PlayButton,
  GameInfoContainer,
  GameImage,
  ProgressBar,
  ProgressFill,
  PercentageText,
  LineSeparator,
  TitleEstrategy,
  ButtonsContainer,
  StrategyContainer,
  GameDetails, // Adicionado o GameDetails na importação
} from "./styles";
import LoadingIcon from "../../../assets/mines/loading.gif";
import { useLocation, useNavigate } from 'react-router-dom';
import Signal from "../../../components/Signal";
import Navbar from "../../../components/Navbar";
import GameErrorMessage from "../../../components/GameErrorMessage/FortuneOx"; // Importe o componente
import FooterMenu from "../../../components/Menu";
import IframeComponent from "../../../components/Fortune/Iframe";

const Spaceman = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [gameData, setGameData] = useState({});

  useEffect(() => {
    const storedGameData = localStorage.getItem('gameData');
    const allGames = JSON.parse(storedGameData);
    const selectedGame = allGames.find(game => game.pagePath === location.pathname);
    setGameData(selectedGame);
  }, [location]);

  const handlePlayNowClick = () => {
    navigate(gameData.pagePath);
  };

  return (
    <Container>
      <Navbar title={gameData.title || "Spaceman"} />

      <Main>
        <GroupGames>
          {gameData.title && (
            <GameContainer>
              <GameDetails>
                <GameInfoContainer>
                  <GameImage src={gameData.imageSrc} alt={gameData.title} />
                  {gameData.percentage !== undefined && (
                    <ProgressBar>
                      <ProgressFill percentage={gameData.percentage}>
                        <PercentageText>{gameData.percentage}%</PercentageText>
                      </ProgressFill>
                    </ProgressBar>
                  )}
                </GameInfoContainer>
                <div>
                  <ButtonsContainer>
                  <TitleEstrategy>Estratégia</TitleEstrategy>
                  {gameData.strategy && (
                    <StrategyContainer percentage={gameData.percentage}>
                      <Strategy>{gameData.strategy}</Strategy>
                    </StrategyContainer>
                  )}
                  {gameData.strategy2 && (
                    <StrategyContainer percentage={gameData.percentage}>
                      <Strategy>{gameData.strategy2}</Strategy>
                    </StrategyContainer>
                  )}
                  {gameData.strategy3 && (
                    <StrategyContainer percentage={gameData.percentage}>
                      <Strategy>{gameData.strategy3}</Strategy>
                    </StrategyContainer>
                  )}

                  </ButtonsContainer>
                </div>
              </GameDetails>
            </GameContainer>
          )}
        </GroupGames>
        <FooterMenu active="" />
        <GameErrorMessage gameLink={gameData.pagePath} />
      <IframeComponent />
      </Main>
      {/* Adicione o componente GameErrorMessage aqui */}
   
    </Container>
  );
};

export default Spaceman;
