import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
justify-content: center;
width: 100%;
height: 100vh;
background-color: #1B2026;
@media (max-width: 768px) {
  height: 100%;
}
`;

export const Main = styled.div`
display: flex;
padding-bottom: 10px;

align-items: center;
width: 100%;
height: calc(100% - 67px - 67px);
flex-direction: column;
background-color: ${theme.colors.primary};
`;

export const Title = styled.h1`
font-size: 14px;
text-align: center;
color: white;
margin-bottom: 10px;
margin-top: 30px;
color: #D6D6D6
`;
export const Title1 = styled.h1`

`;
