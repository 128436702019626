import styled from 'styled-components';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 700px;
  width: 100%;
  padding: 50px 10px;
  height: 50px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const IconContainer = styled.div`
  background-color: rgba(90, 100, 252, 0.5); /* Cor roxa mais escura */
  height: 50px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px; /* Arredondado somente do lado esquerdo */
  font-size: 24px; /* Tamanho do ícone */
`;
export const MessageContainer = styled.div`
  background-color: rgba(138, 63, 252, 0.5); /* Cor roxa com opacidade */
  height: 50px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0; /* Arredondado somente do lado direito */
  flex-grow: 1; /* Faz com que preencha o espaço restante */
`;

export const Message = styled.p`
  font-family: 'Montserrat', sans-serif; /* Usando Montserrat */
  font-size: 0.8rem;
  color: white;
  text-align: left;
  margin: 0;
`;
