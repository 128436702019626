import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";


import DiamondImg from "../../assets/mines/mines.png";
import Vazio from "../../assets/mines/minesVazio.png";

export default function Mines({ value }) {
  return (
    <>
      {value === 0 ? (
        <Vazio1>
          <Vazio2>
          <Diamond src={Vazio} alt="Sinal" />
        </Vazio2>
        </Vazio1>
      ) : (
        value === 2 ?(
        <Squared>
          <Diamond src={DiamondImg} alt="Sinal" />
        </Squared>):(
          <SquaredVazio>
          <Diamond src={Vazio} alt="Sinal" />
        </SquaredVazio>
        )
      )}
    </>
  );
}

const Squared = styled.div`
  height: 32px;
  width: 38px;
  background: rgb(8, 63, 108);
  border: 2px solid rgb(1, 85, 149);
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
`;
const SquaredVazio = styled.div`
  height: 32px;
  width: 38px;
  border: 2px solid ${theme.colors.button};
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
`;

const Vazio1 = styled.div`
  height: 32px;
  width: 38px;
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
`;
const Vazio2 = styled.div`
  height: 32px;
  width: 38px;
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
`;

const Circle = styled.div`
  background-color: rgb(1, 85, 149);
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

const Diamond = styled.img`
  height: 32px;
  width: 38px;
  
`;
