import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Container,
  Main,
  BannerImg,
  ProPlan,
  ProPlanTitle,
  ProPlanSubtitle,
  ProPlanButton,
  ProPlanButtonTitle,
  Games,
  GroupGames,
  GamesTitle,
  GamesTitleIcon,
  DownloadApps,
  Apps,
  App,
} from "./styles";

import { RiMedalLine } from "react-icons/ri";
import { BiJoystick } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { AiFillApple, AiFillAndroid } from "react-icons/ai";

import Navbar from "../../components/Navbar";
import GamesLink from "../../components/Games";
import Menu from "../../components/Menu";


import Banner from "../../assets/BANNERNOVO.webp";


export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    if (utmSource) {
      localStorage.setItem('utm_source', utmSource);
    }
  }, []);

  const handleBannerClick = () => {
    const utmSource = localStorage.getItem('utm_source') || 'aplicativo';
    const redirectUrl = `https://go.apostaganha.bet/visit/?bta=71579&brand=apostaganha&utm_campaign=${utmSource}`;
    window.location.href = redirectUrl;
  };

  return (
    <Container>
      <Navbar title="Instalação" />
      <Main>
      <div onClick={handleBannerClick} style={{ cursor: 'pointer' }}>
          <BannerImg src={Banner} alt="Banner Semana Maluca" />
        </div>

        <DownloadApps>
          <GamesTitle>
            <GamesTitleIcon>
              <HiDownload color="#fff" size={24} />
            </GamesTitleIcon>
            Instalação do App
          </GamesTitle>

          <Apps>
            <Link to="/Page_Ios">
              <App>
                <AiFillApple color="#fff" size={20} />
                IOS
              </App>
            </Link>
            <Link to="/Page_Android">
              <App>
                <AiFillAndroid color="#fff" size={20} />
                Android
              </App>
            </Link>
          </Apps>
        </DownloadApps>

      </Main>
      <Menu active="baixar" />
    </Container>

  );
}
