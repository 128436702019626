import styled from "styled-components";
// import theme from "../../styles/theme";

export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: white;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  margin-top: 20px;
  padding-bottom: 10px;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color:white;
  padding-bottom: 20px;
`;

export const Loading = styled.img`
width:20px;
`;

export const MinesField = styled.div`
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.25);;
  display: flex;
  width: 90%;
  height: 70px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
`;

export const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 colunas com largura igual */
  grid-template-rows: repeat(5, 1fr); /* 5 linhas com altura igual */
  gap: 5px; /* espaçamento entre as células da grade */
`;

export const IdentifySignal = styled.button`
  margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: red;

  transition: 100ms;

  &:hover {
    opacity: 0.5;
  }
`;


export const WaitButton = styled.div`
 margin-top: 10px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;

  width: 90%;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: red;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Counter = styled.p`
display: flex;
margin-top: 12px;
text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  white-space: normal;
  margin-bottom: 5px;
`;
export const ResultCounter = styled.p`
  /* Add your styles for the ResultCounter here */
  /* For example:
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  */
`;