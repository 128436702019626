import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Tiger from '../../assets/JogosSlot/Tigers.png'
import Ox from '../../assets/JogosSlot/Fortune OX.webp'
import Coelho from '../../assets/JogosSlot/fortune_rabbit.webp'
import Mouse from '../../assets/JogosSlot/fortune_mouse.webp'
import Mines from '../../assets/JogosSlot/mines.webp'
import Ninja from '../../assets/JogosSlot/ninjacrash.webp'


// Funções para gerar dados aleatórios
const generateRandomId = () => {
  return Math.floor(100000 + Math.random() * 900000).toString().replace(/\d{3}$/, '***');
};

const generateRandomWinAmount = () => {
  const amount = (Math.random() * (299 - 10) + 10).toFixed(2);
  return `R$ ${amount}`;
};

// Animação de fade in
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Componentes de estilo
const CardContainer = styled.div`
  background-color: rgba(138, 63, 252, 0.5); /* Alterei para a cor com opacidade */
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 15px 0;
  width: 700px;
  border-radius: 10px; /* Aumentei o raio do border-radius */
  position: relative;
  opacity: 0; /* Inicialmente invisível */
  animation: ${fadeIn} 0.5s ease forwards; /* Animação de fade in */
  backdrop-filter: blur(10px); /* Adiciona efeito de vidro */
  @media (max-width: 768px) {
    width: 95%
  }
`;

const GameLogo = styled.div`
  background-image: url('${props => props.logoUrl}');
  background-size: cover;
  border-radius: 10px 0;
  left: 0px;
  height: 100%;
  position: absolute;
  width: 80px;
  z-index: 1;
`;


const GameInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column; // Organiza os itens em uma coluna
  justify-content: center; // Centraliza o conteúdo verticalmente
  align-items: start; // Alinha itens à esquerda
  margin-left: 88px; // Espaço para o logotipo mais um pequeno espaço extra
`;

const Congratulations = styled.div`
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #c9c9c9;
`;

const PlayerInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
`;

const SubTitle = styled.div`
  font-size: 0.7em;
  margin-top: 5px;
  color: #c9c9c9;
`;
// Objeto que mapeia títulos de jogos para URLs de imagens
const gameImages = {
  'Tiger': Tiger,
  'Fortune Ox': Ox,
  'Fortune Rabbit': Coelho,
  'Fortune Mouse': Mouse,
  'Mines': Mines,
  'Ninja Crash': Ninja,
};
const Card = () => {
  const [userId, setUserId] = useState(generateRandomId());
  const [winAmount, setWinAmount] = useState(generateRandomWinAmount());

  // Adicione um estado para a URL da imagem do jogo
  const [logoUrl, setLogoUrl] = useState();
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {

    setUserId(generateRandomId());
    setWinAmount(generateRandomWinAmount());
    const gameTitles = Object.keys(gameImages);
    const randomGameKey = gameTitles[Math.floor(Math.random() * gameTitles.length)];
    setLogoUrl(gameImages[randomGameKey]);
    setSubTitle(randomGameKey);

    const interval = setInterval(() => {
      setUserId(generateRandomId());
      setWinAmount(generateRandomWinAmount());
      const gameTitles = Object.keys(gameImages);
      const randomGameKey = gameTitles[Math.floor(Math.random() * gameTitles.length)];
      setLogoUrl(gameImages[randomGameKey]);
      setSubTitle(randomGameKey);
    }, 3000); // Novo card a cada 3 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <CardContainer>
      <GameLogo logoUrl={logoUrl} />
      <GameInfo>
        <Congratulations>Parabéns!</Congratulations>
        <PlayerInfo>{userId} Ganhou {winAmount}</PlayerInfo>
        <SubTitle>{subTitle}</SubTitle>
      </GameInfo>
    </CardContainer>
  );
};

export default Card;
