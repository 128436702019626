// styles.js
import styled, { css, keyframes } from "styled-components";
import theme from "../../styles/theme";
import { Link } from "react-router-dom"; // Importe o componente Link do React Router

export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden; // Evita a rolagem
  background-color: #1B2026;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  padding-bottom: 10px;
  align-items: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  width: 100%;
  height: calc(100% - 67px - 67px);
  flex-direction: column;
  background-color: ${theme.colors.primary};
  padding: 30px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color || "#7B1B93"};
  margin-right: 15px;
  color: white;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
`;

export const StepText = styled.p`
  font-size: 16px;
  color: white;
  margin: 0;
  display: flex;
  align-items: center;

  ${({ isLongText }) =>
    isLongText &&
    css`
      font-size: 14px;
    `}

  ${({ isLongText }) =>
    isLongText &&
    css`
      @media (min-width: 769px) {
        white-space: nowrap;
        max-width: 200px;
      }
    `}
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const blinkSubtitleAnimation1 = keyframes`
  0%, 100% {
    color: white;
  }
  50% {
    color: white;
  }
`;

const blinkSubtitleAnimation2 = keyframes`
  0%, 100% {
    color: #d2b107;
  }
  50% {
    color: #ffd600;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const BlinkingText1 = styled.span`
  animation: ${blinkSubtitleAnimation1} 1s infinite;
`;

export const BlinkingText2 = styled.span`
  animation: ${blinkSubtitleAnimation2} 1s infinite;
`;

export const MobileContainer = styled(Container)`
  min-height: 100%;
`;

export const MobileMain = styled(Main)`
  padding: 20px;
  max-width: 100%;
`;

export const MobileStepIcon = styled(StepIcon)`
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
`;

export const MobileStepText = styled(StepText)`
  font-size: 14px;
  text-align: left;
`;

export const SubtitleLogin = styled.p`
  font-size: 16px;
  margin-top: 5px;
  padding-bottom: 0px;
  color: white;
  text-align: center;
`;
export const SignupLink = styled(Link)`
  font-size: 16px;
  color: ${theme.colors.button}; /* Use a mesma cor do botão */
  text-decoration: none;
  cursor: pointer;
`;

export const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const LoginButton = styled.button`
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  width: 325px;
  margin-bottom: 20px;
  max-width: 400px;
  height: 50px;
  border-radius: 25px;
  color: white;
  background-color: ${theme.colors.button};
  cursor: pointer;
  transition: 500ms linear;

  :hover {
    background-color: ${theme.colors.buttonTransparent};
  }
`;
export const ButtonVoltar = styled.button`
  display: flex;
  align-items: center; /* Alinhar o ícone verticalmente */
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  width: 120px;
  margin-bottom: 20px;
  max-width: 400px;
  height: 100px;
  border-radius: 25px;
  color: white;
  background-color: ${theme.colors.button};
  cursor: pointer;
  transition: 500ms linear;
  padding-left: 20px; /* Espaçamento para o ícone */

  :hover {
    background-color: ${theme.colors.buttonTransparent};
  }

  /* Estilizar o ícone */
  svg {
    margin-right: 10px; /* Espaçamento entre o texto e o ícone */
    font-size: 20px; /* Tamanho do ícone */
  }
`;


const blinkAnimation = keyframes`
  0%, 100% {
    color: #8A3FFC;
  }
  50% {
    color: purple;
  }
`;

export const AccessTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: green; /* Cor verde do sucesso */
  margin-bottom: 10px;
  margin-top: 30px;
  animation: ${blinkAnimation} 1s infinite; /* Aplicar animação de piscar */
`;






