import styled from 'styled-components';
import theme from '../../styles/theme';

export const Container = styled.div`
background-color: ${theme.colors.primary};
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const Title = styled.h1`
color: #fff;
font-size: 80px;
`;
export const Sub = styled.h2`
color: #fff;
`;