import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import theme from "../../styles/theme";
import { v4 as uuidv4 } from 'uuid';


const slideAnimation = keyframes`
  to {
    transform: translateX(calc(-100% - 20px));
  }
`;

const SlidingContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
`;

const slideSpeed = '30s'; // Defina a velocidade da animação aqui
const slideDuration = '30s'; // Defina a duração total da animação aqui


const SlidingInnerContainer = styled.div`
  display: flex;
  animation: ${slideAnimation} ${slideSpeed} linear infinite;
  animation-play-state: running;
  animation-delay: -${slideDuration}; // Começa a animação após a duração total
  @media (max-width: 768px) {
    margin-top: 20px;
    animation: ${slideAnimation} ${slideSpeed} linear infinite;
    animation-delay: -${slideDuration}; // Mesmo atraso em telas menores
  }
`;


const SlidingNamesContainer = styled.div`
background: #0B0B0E;
padding: 8px;
border-radius: 10px;
display: inline-flex;
align-items: center;
box-shadow: 0 0px 15px  ${theme.colors.button}; /* Ajuste o valor do box-shadow */
white-space: nowrap;
color: #ffffff;
font-size: 18px;
margin-right: 20px;

`;

const PixIcon = styled.svg`
  width: 20px;
  height: 19px;
  fill: #30B6A8;
  margin-right: 10px;
`;

const Name = styled.div`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  font-weight: 700;
`;

const Space = styled.span`
  margin: 0 5px;
`;

const Amount = styled.span`
  font-size: 14px;
  color: #8A3FFC;
`;

const Description = styled.div`
  font-size: 14px;
  color: #8A3FFC;
`;

const Description1 = styled.div`
  font-size: 14px;
  color: #777;
`;

const namesArray = [
  "João", "Maria", "Pedro", "Ana", "Lucas", "Sofia", "Matheus", "Laura", "Guilherme", "Júlia", "Enzo", "Isabela", "Rafael", "Beatriz", "Gabriel", "Giovanna", "André", "Luísa",
  "Thiago", "Manuela", "Eduardo", "Mariana", "Davi", "Helena",
  // Adicione mais nomes aqui, se desejar...
];


const gamesList = [
  {
    id: 1,
    title: ' Tiger',
    game: ' Tiger'
  },
  {
    id: 2,
    title: 'Fortune Ox',
    game: 'Fortune Ox'
  },
  {
    id: 3,
    title: ' Rabbit',
    game: ' Rabbit'
  },
  {
    id: 4,
    title: ' Mouse',
    game: ' Mouse'
  },
  {
    id: 5,
    title: 'Dragon',
    game: 'Dragon'
  },
  {
    id: 12,
    title: 'Double ',
    game: 'Double '
  },
  {
    id: 6,
    title: 'Panda',
    game: 'Panda'
  },
  {
    id: 7,
    title: 'Candy',
    game: 'Candy'
  },
  {
    id: 8,
    title: 'Crypto',
    game: 'Crypto'
  },
  {
    id: 9,
    title: ' Ganesha',
    game: ' Ganesha'
  },
  {
    id: 10,
    title: 'Pinguim',
    game: 'Pinguim'
  },
  {
    id: 11,
    title: 'Piggy Gold',
    game: 'Piggy Gold'
  },
];

const SlidingNames = () => {
  const [scrollingPixs, setScrollingPixs] = useState([]);

  useEffect(() => {
    const generateRandomPix = () => {
      const newNameIndex = Math.floor(Math.random() * namesArray.length);
      const newGameIndex = Math.floor(Math.random() * gamesList.length);

      return {
        nameIndex: newNameIndex,
        gameIndex: newGameIndex,
        amount: (Math.random() * (396.98 - 1) + 1).toFixed(2),
        key: uuidv4(),
      };
    };

    const initialPixs = Array.from({ length: 200 }, () => generateRandomPix());
    setScrollingPixs(initialPixs);

    const containerWidth = document.querySelector('#sliding-inner-container').offsetWidth;

    const interval = setInterval(() => {
      setScrollingPixs(prevPixs => {
        if (prevPixs.length >= 100) {
          return [...prevPixs.slice(1), generateRandomPix()];
        } else {
          return [...prevPixs, generateRandomPix()];
        }
      });

      const slidingContainer = document.querySelector('#sliding-container');
      const slidingInnerContainer = document.querySelector('#sliding-inner-container');

      if (slidingContainer && slidingInnerContainer) {
        slidingContainer.scrollLeft = slidingInnerContainer.offsetWidth - containerWidth;
      }
    }, 5000); // Intervalo de 5 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <SlidingContainer id="sliding-container">
      <SlidingInnerContainer id="sliding-inner-container">
        {scrollingPixs.map(pix => (
          <SlidingNamesContainer key={pix.key}>
            <PixIcon>
              <svg width="20" height="19" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4986 21.0494L15.1342 16.6851C14.9747 16.5256 14.7862 16.4966 14.6847 16.4966C14.5832 16.4966 14.3948 16.5256 14.2353 16.6851L9.85642 21.0639C9.36344 21.5569 8.59497 22.3543 6.02856 22.3543L11.4079 27.7191C12.2235 28.5337 13.329 28.9913 14.4818 28.9913C15.6345 28.9913 16.74 28.5337 17.5556 27.7191L22.9494 22.3398C21.63 22.3398 20.528 22.0789 19.4986 21.0494ZM9.85642 7.92738L14.2353 12.3062C14.3513 12.4222 14.5253 12.4947 14.6847 12.4947C14.8442 12.4947 15.0182 12.4222 15.1342 12.3062L19.4696 7.97088C20.499 6.89792 21.6735 6.65143 22.9929 6.65143L17.5991 1.27213C16.7835 0.457543 15.678 0 14.5253 0C13.3725 0 12.267 0.457543 11.4514 1.27213L6.07206 6.63693C8.62397 6.63693 9.40694 7.4779 9.85642 7.92738Z" fill="#30B6A8"></path><path d="M27.7046 11.3781L24.4423 8.10126H22.6153C21.8324 8.10126 21.0494 8.42025 20.5129 8.98573L16.1631 13.3356C15.7571 13.7416 15.2206 13.9445 14.6841 13.9445C14.1317 13.9368 13.6029 13.719 13.2052 13.3356L8.82635 8.94223C8.27537 8.39125 7.52139 8.07227 6.72392 8.07227H4.5925L1.27213 11.4071C0.457543 12.2227 0 13.3283 0 14.481C0 15.6337 0.457543 16.7393 1.27213 17.5549L4.5925 20.8898H6.73842C7.52139 20.8898 8.27537 20.5708 8.84085 20.0198L13.2197 15.641C13.6257 15.235 14.1621 15.032 14.6986 15.032C15.2351 15.032 15.7716 15.235 16.1776 15.641L20.5419 20.0053C21.0929 20.5563 21.8469 20.8753 22.6443 20.8753H24.4713L27.7336 17.5984C28.5517 16.7681 29.0078 15.6478 29.0023 14.4823C28.9969 13.3168 28.5304 12.2008 27.7046 11.3781Z" fill="#30B6A8"></path></svg>
            </PixIcon>
            <Name>{namesArray[pix.nameIndex]}</Name>
            <Space />
            <Description1>ganhou </Description1>
            <Space />
            <Amount>R${pix.amount}</Amount>
            <Space />
            <Description>no {gamesList[pix.gameIndex].game}</Description>
          </SlidingNamesContainer>
          
        ))}
      </SlidingInnerContainer>
      <Description1>ㅤ</Description1>
    </SlidingContainer>
  );
};

export default SlidingNames;
