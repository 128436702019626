import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.primary};
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const WelcomeImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 25px;
  margin-top: 40px;
`;

export const Title = styled.h1`
  margin: 20px 0;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: white;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 20px;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Input = styled.input`
  width: 325px;
  max-width: 400px;
  display: flex;
  height: 50px;
  border: none;
  margin-right: 20px;
  border-radius: 4px;
  padding: 12px;
  font-size: 18px;
  background-color: ${(props) =>
    props.isFocused ? theme.colors.button : "white"};
  color: ${(props) => (props.isFocused ? "white" : "black")};
  transition: background-color 0.3s, color 0.3s;
  
  ::placeholder {
    color: ${(props) =>
    props.isFocused ? "white" : theme.colors.placeholder};
  }

  :focus {
    background-color: ${theme.colors.button};
    color: white;
    outline: none;
  }
`;

export const LoginButton = styled.button`
  margin-top: 20px;
  border: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  width: 325px;
  max-width: 400px;
  height: 50px;
  border-radius: 4px;
  color: white;
  background-color: ${theme.colors.button};
  cursor: pointer;
  transition: 500ms linear;

  :hover {
    background-color: ${theme.colors.buttonTransparent};
  }
`;
export const SignupLink = styled.a`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: white;
  }
`;  
