import React from 'react';
import { Container, Title, Main } from './styles';

import Navbar from '../../components/Navbar';
import Menu from "../../components/Menu";

export default function Notification() {
  return (
    <Container>
      <Navbar title="Notificações" />
      <Main>
        <Title>Sem notificações no momento.</Title>
      </Main>
      <Menu active="inicio" />
    </Container>
  );
}