import React from 'react';
import { IframeContainer, IframeComponentWrapper, StyledIframe } from './styles';

function IframeComponent() {
  return (
    <IframeContainer>
      <IframeComponentWrapper>
        <StyledIframe
          src="https://external.apostaganha.bet/#/cassino/fortune-mouse
          "
          title="Fortune Mouse"
        ></StyledIframe>
      </IframeComponentWrapper>
    </IframeContainer>
  );
}

export default IframeComponent;
