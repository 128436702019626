import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { AlertContainer, IconContainer, MessageContainer, Message } from './styles';

const AlertCard = () => {
  return (
    <AlertContainer>
      <IconContainer>
        <NotificationsNoneIcon style={{ fontSize: 'inherit', color: 'white' }} />
      </IconContainer>
      <MessageContainer>
        <Message>
          Atualize a página pelo menos uma vez a cada 5 minutos para ficar atualizado.
        </Message>
      </MessageContainer>
    </AlertContainer>
  );
};

export default AlertCard;
